/* Mobile screens - background image removed */
.bg-custom {
  background-color: #eee;}

@media (min-width:500px) {
  /* For medium (md) screens and above, apply the background image */
  .bg-custom {
    background-image: url('https://storage.googleapis.com/notion-smartlead-record/img_group_6.jpg');
    background-repeat: no-repeat;
    background-size: 100% 100vh;
  }
}

/* Fade transitions */
.fade-enter {
  opacity: 0;
  transform: translateY(20px);
}

.fade-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}

.fade-exit {
  opacity: 1;
  transform: translateY(0);
}

.fade-exit-active {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 300ms, transform 300ms;
}
