@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/ManropeRegular.ttf");
  font-family: "Manrope";
  font-weight: 400;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/ManropeBold.ttf");
  font-family: "Manrope";
  font-weight: 700;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/ManropeMedium.ttf");
  font-family: "Manrope";
  font-weight: 500;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/ManropeLight.ttf");
  font-family: "Manrope";
  font-weight: 300;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/ManropeSemiBold.ttf");
  font-family: "Manrope";
  font-weight: 600;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/PlusJakartaSansMedium.ttf");
  font-family: "Plus Jakarta Sans";
  font-weight: 500;
}
